/** @jsx jsx */

import PropTypes from 'prop-types'
import { useRef } from 'react'
import { jsx } from 'theme-ui'
import Glide from 'react-glidejs'
import { GatsbyImage } from 'gatsby-plugin-image'
import ReactMarkdown from 'react-markdown'

import {
  SlideCaption,
  SlideImage,
  SlideWrapper,
  Title,
  Wrapper,
} from './styles'
import Controls from './Controls'

const ImageSlider = ({ content }) => {
  const gliderRef = useRef(null)
  const contentData = content?.content ? content?.content : content.media

  return (
    <Wrapper data-testid={content.id}>
      {content?.title && <Title as="h2">{content.title}</Title>}
      <Glide ref={gliderRef} type="slider" perView={1} hideArrows>
        {contentData.map(media => {
          return(
            <SlideWrapper key={media.id}>
              <SlideImage>
                {media?.gatsbyImageData && <GatsbyImage image={media.gatsbyImageData} alt={media.title} />}
                {media?.media && <GatsbyImage image={media?.media.gatsbyImageData} alt={media.title} />}
              </SlideImage>
              {!media?.media && <SlideCaption>{media.description}</SlideCaption>}
              {media?.media &&
                <SlideCaption>
                  <ReactMarkdown
                    components={{
                      h1:'p',
                      h2:'p',
                      h3:'p'
                    }}
                  >
                    {media?.description?.description}</ReactMarkdown>
                </SlideCaption>
              }
            </SlideWrapper>
          )
        })}
      </Glide>
      <Controls media={contentData} gliderRef={gliderRef} />
    </Wrapper>
  )
}

ImageSlider.propTypes = {
  content: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    media: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        gatsbyImageData: PropTypes.shape({}),
        title: PropTypes.string,
        description: PropTypes.string
      })
    )
  })
}

export default ImageSlider
