import styled from '@emotion/styled'
import { Heading } from 'theme-ui'

export const Wrapper = styled.section`
  padding: 32px 0;
  background-color: ${props => props.theme.colors.cream};

  .Glide-leftArrow {
    left: 0;
  }
  .Glide-rightArrow {
    right: 0;
  }

  @media screen and (min-width: 992px) {
    padding: 64px 0;
  }
`

export const MobileWrapper = styled.div`
  @media screen and (min-width: 992px) {
    display: none;
  }
`

export const DesktopWrapper = styled.div`
  display: none;

  @media screen and (min-width: 992px) {
    display: block;
  }
`

export const Title = styled(Heading)`
  text-align: center;
`

export const Grid = styled.div`
  display: grid;
  gap: 32px;

  @media screen and (min-width: 992px) {
    grid-template-columns: repeat(3, 1fr);
  }
`
