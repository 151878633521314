/** @jsx jsx */

import { GatsbyImage } from 'gatsby-plugin-image'
import Link from 'gatsby-link'
import PropTypes from 'prop-types'
import { Button, jsx } from 'theme-ui'
import { useAnalytics } from '@chordcommerce/gatsby-theme-autonomy'
import ProductLink from '~/components/Product/Link'
import useProductVariants from '~/hooks/components/use-product-variants'
import { Wrapper, ImageWrapper, ContentWrapper } from './Card.styles'

const ProductCard = ({ product, position }) => {
  const { trackProductClicked } = useAnalytics()
  const { currentVariant } = useProductVariants(product.variants, 0, product)
  const title = product?.name || currentVariant?.mainImage?.title
  const image =
    product?.cardImage?.gatsbyImageData ||
    currentVariant?.mainImage?.gatsbyImageData

  return (
    <Wrapper data-testid="product-card">
      {currentVariant.mainImage && (
        <ImageWrapper>
          <ProductLink slug={product.slug}>
            <GatsbyImage image={image} alt={`Shop ${title}`} />
          </ProductLink>
        </ImageWrapper>
      )}
      <ContentWrapper>
        <div style={{ margin: '16px 0' }}>
          <ProductLink
            sx={{
              display: 'block',
              textTransform: 'none',
              textAlign: 'center',
              marginBottom: ['4px', null, '6px'],
              fontWeight: 'bold'
            }}
            slug={product.slug}
          >
            {product.name}
            <span style={{ marginLeft: 10, fontWeight: 'normal' }}>
              | ${currentVariant.price}
            </span>
          </ProductLink>
        </div>
        <Button
          as={Link}
          to={`/products/${product.slug}`}
          variant="secondary"
          style={{
            width: 250,
            margin: '0 auto'
          }}
          onClick={() => {
            trackProductClicked({
              product: { slug: product.slug },
              placement: 'Shop the story',
              position
            })
          }}
        >
          Shop Now →
        </Button>
      </ContentWrapper>
    </Wrapper>
  )
}

ProductCard.propTypes = {
  product: PropTypes.shape({
    collection: PropTypes.arrayOf(
      PropTypes.shape({
        slug: PropTypes.string,
        title: PropTypes.string
      })
    ),
    mainImage: PropTypes.shape({
      gatsbyImageData: PropTypes.shape({}),
      title: PropTypes.string,
      url: PropTypes.string
    }),
    cardImage: PropTypes.shape({
      gatsbyImageData: PropTypes.shape({}),
      title: PropTypes.string,
      url: PropTypes.string
    }),
    name: PropTypes.string,
    price: PropTypes.number,
    regularPrice: PropTypes.number,
    size: PropTypes.string,
    isGiftCard: PropTypes.bool,
    sku: PropTypes.string,
    slug: PropTypes.string,
    shortDescription: PropTypes.string,
    variants: PropTypes.arrayOf(PropTypes.shape({})),
    optionTypes: PropTypes.arrayOf(PropTypes.shape({}))
  }),
  position: PropTypes.number
}

export default ProductCard
