/** @jsx jsx */

import PropTypes from 'prop-types'
import { useRef } from 'react'
import { Container, jsx } from 'theme-ui'
import Glide from 'react-glidejs'
import { MobileWrapper, DesktopWrapper, Wrapper, Title, Grid } from './styles'
import ProductCard from './Card'
import VariantCard from './VariantCard'

const ShopStory = ({ content }) => {
  const gliderRef = useRef(null)
  const products = content.content

  if (!products) return null

  return (
    <Wrapper>
      <Container variant="small">
        <Title as="h2" variant="secondaryMedium">
          {content.title}
        </Title>
        <MobileWrapper>
          <Glide ref={gliderRef} type="carousel" perView={1}>
            {products.map((product, index) => {
              return product.product ? (
                <VariantCard
                  key={`${product.slug}-${index}`}
                  variant={product}
                />
              ) : (
                <ProductCard
                  key={`${product.slug}-${index}`}
                  product={product}
                />
              )
            })}
          </Glide>
        </MobileWrapper>
        <DesktopWrapper data-testid="desktop-wrapper">
          {products.length > 3 ? (
            <Glide ref={gliderRef} type="carousel" perView={3}>
              {products.map((product, index) => {
                return product.product ? (
                  <VariantCard
                    key={`${product.slug}-${index}`}
                    variant={product}
                    position={index}
                  />
                ) : (
                  <ProductCard
                    key={`${product.slug}-${index}`}
                    product={product}
                    position={index}
                  />
                )
              })}
            </Glide>
          ) : (
            <Grid>
              {products.map((product, index) => {
                return product.product ? (
                  <VariantCard
                    key={product.slug}
                    variant={product}
                    position={index}
                  />
                ) : (
                  <ProductCard
                    key={product.slug}
                    product={product}
                    position={index}
                  />
                )
              })}
            </Grid>
          )}
        </DesktopWrapper>
      </Container>
    </Wrapper>
  )
}

ShopStory.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.arrayOf(PropTypes.shape({}))
  })
}

export default ShopStory
