/** @jsx jsx */

import { GatsbyImage } from 'gatsby-plugin-image'
import Link from 'gatsby-link'
import PropTypes from 'prop-types'
import { Button, jsx } from 'theme-ui'
import ProductLink from '~/components/Product/Link'
import { Wrapper, ImageWrapper, ContentWrapper } from './Card.styles'

const VariantCard = ({ variant }) => {
  const product = variant.product[0]
  const image = variant.cardImage || variant.mainImage

  return (
    <Wrapper data-testid="variant-card">
      {variant.mainImage && (
        <ImageWrapper>
          <ProductLink slug={product.slug}>
            <GatsbyImage image={image.gatsbyImageData} alt={`Shop ${product.name}`} />
          </ProductLink>
        </ImageWrapper>
      )}
      <ContentWrapper>
        <div style={{ margin: '16px 0' }}>
          <ProductLink
            sx={{
              display: 'block',
              textTransform: 'none',
              textAlign: 'center',
              marginBottom: ['4px', null, '6px'],
              fontWeight: 'bold'
            }}
            slug={product.slug}
          >
            {product.name}
            <span style={{ marginLeft: 10, fontWeight: 'normal' }}>
              | ${variant.price}
            </span>
          </ProductLink>
        </div>
        <Button
          as={Link}
          to={`/products/${product.slug}`}
          variant="secondary"
          style={{ width: 250, margin: '0 auto' }}
        >
          Shop Now →
        </Button>
      </ContentWrapper>
    </Wrapper>
  )
}

VariantCard.propTypes = {
  variant: PropTypes.shape({
    mainImage: PropTypes.shape({
      gatsbyImageData: PropTypes.shape({}),
      title: PropTypes.string
    }),
    cardImage: PropTypes.shape({
      gatsbyImageData: PropTypes.shape({}),
      title: PropTypes.string
    }),
    price: PropTypes.number,
    product: PropTypes.arrayOf(
      PropTypes.shape({
        slug: PropTypes.string,
        name: PropTypes.string
      })
    )
  })
}

export default VariantCard
