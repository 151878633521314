import { graphql, useStaticQuery } from 'gatsby'

const QUERY = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`

const useArticleRichResults = ({
  articleBody,
  author,
  dateCreated,
  datePublished,
  description,
  image,
  title,
}) => {
  const { site: { siteMetadata: {
    siteUrl,
    title: publisher,
  } } } = useStaticQuery(QUERY)

  return {
    '@context': 'https://schema.org',
    '@type': 'Article',
    name: title,
    articleBody,
    headline: title,
    image,
    description,
    datePublished,
    dateCreated,
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': siteUrl,
    },
    author: {
      '@type': 'Person',
      name: author,
    },
    publisher: {
      '@type': 'Corporation',
      name: publisher,
    },
  }
}

export default useArticleRichResults
