import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import { Container } from 'theme-ui'
import { Category, Grid, Image, Title, Wrapper } from './styles'

const Header = ({ category, image, title }) => {
  return (
    <Wrapper>
    <Container variant="small">
      <Grid>
        {category && <Category>{category.name}</Category>}
        <Title as="h1" variant="secondaryMedium">
          {title}
        </Title>
        <Image>
          <GatsbyImage image={image.gatsbyImageData} alt={image.title} />
        </Image>
      </Grid>
    </Container>
  </Wrapper>
  )
}

Header.propTypes = {
  category: PropTypes.shape({
    name: PropTypes.string
  }),
  title: PropTypes.string,
  image: PropTypes.shape({
    gatsbyImageData: PropTypes.shape({}),
    title: PropTypes.string
  })
}

export default Header
