/** @jsx jsx */

import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import { jsx } from 'theme-ui'
import {
  ControlsArrowLeft,
  ControlsArrowRight,
  ControlsSlideNumbers,
  ControlsWrapper
} from './styles'

const SliderControls = ({ media, gliderRef }) => {
  const [active, setActive] = useState(0)

  useEffect(() => {
    let mounted = true
    if (gliderRef.current) {
      gliderRef.current.on('move.after', function () {
        if (mounted) {
          setActive(gliderRef.current.index)
        }
      })
    }
    return () => (mounted = false)
  }, [gliderRef])

  return (
    <ControlsWrapper>
      <ControlsArrowLeft
        disabled={active === 0}
        onClick={() => gliderRef.current.go('<')}
        aria-label="Previous"
      />
      <ControlsArrowRight
        disabled={active === media.length - 1}
        onClick={() => gliderRef.current.go('>')}
        aria-label="Next"
        data-active={active}
      />
      <ControlsSlideNumbers aria-label="Slide Numbers">
        <p aria-label="Active Slide">{`${active < 10 ? '0' : ''}${
          active + 1
        }`}</p>
        <p>/</p>
        <p>{`${media.length < 10 ? '0' : ''}${media.length}`}</p>
      </ControlsSlideNumbers>
    </ControlsWrapper>
  )
}

SliderControls.propTypes = {
  media: PropTypes.arrayOf(PropTypes.shape({})),
  gliderRef: PropTypes.shape({
    current: PropTypes.shape({
      on: PropTypes.func,
      go: PropTypes.func,
      index: PropTypes.number
    })
  })
}

export default SliderControls
