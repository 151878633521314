import styled from '@emotion/styled'
import { Heading } from 'theme-ui'

export const Wrapper = styled.div`
  padding: 0 0 32px 0;
  position: relative;

  .glide__slides {
    display: grid;
    grid-auto-flow: column;
  }

  div[data-glide-el='controls'] {
    display: none;
  }
`

export const Title = styled(Heading)`
  font-family: ${props => props.theme.fonts.secondary};
  font-size: 20px;
  margin-bottom: 16px;

  @media screen and (min-width: 992px) {
    font-size: 24px;
  }
`

/**
 * Slide
 */
export const SlideWrapper = styled.div`
  height: 100%;

  &:only-child {
    margin-top: 1rem;
  }
`

export const SlideImage = styled.div`
  .gatsby-image-wrapper {
    height: 100%;
  }
`

export const SlideCaption = styled.p`
  font-family: ${props => props.theme.fonts.secondary};
  font-size: 15px;
  margin-top: 12px;

  img, table, hr, code, ul, ol, li {
    display:none;
  }
  p {
    margin-bottom:.75rem;

    & > a {
      text-decoration: underline;
      text-decoration-thickness: max(0.08em, 1px);
      text-underline-offset: 0.15em;
    }
  }

  a{
    text-decoration:underline;
    text-decoration-thickness: max(0.08em, 1px);
    text-underline-offset: 0.15em;
  }

`

/**
 * Controls
 */
export const ControlsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
`

const Arrow = styled.button`
  background: none;
  width: 35px;
  height: 35px;
  border: 1px solid black;
  border-radius: 50%;
  margin-right: 12px;
  cursor: pointer;

  &:before {
    display: inline-block;
    position: relative;
    content: '';
    width: 10px;
    height: 10px;
    border-style: solid;
    border-width: 2px 2px 0 0;
  }

  &[disabled] {
    color: black;
    opacity: 0.4;
    cursor: default;
  }
`

export const ControlsArrowLeft = styled(Arrow)`
  &:before {
    transform: rotate(-135deg);
    left: 2px;
    top: 2px;
  }
`

export const ControlsArrowRight = styled(Arrow)`
  &:before {
    transform: rotate(45deg);
    right: 2px;
    top: 2px;
  }
`

export const ControlsSlideNumbers = styled.div`
  display: flex;
  align-items: center;

  p {
    font-size: 16px;
    margin: 0 2px;

    &:first-of-type {
      font-weight: bold;
    }
  }
`
