import styled from '@emotion/styled'

export const ContentWrapper = styled.div`
  ul {
    list-style: none;
    margin-top: 16px;
  }

  li {
    padding: 16px 0;
    border-top: 2px dotted #ddd;

    strong {
      display: block;
    }
  }

  @media screen and (min-width: 992px) {
    li {
      display: flex;
      align-items: center;
      padding: 32px 0;

      strong {
        width: 25%;
        min-width: 25%;
        margin-right: 32px;
      }
    }
  }
`
