import styled from '@emotion/styled'

export const Wrapper = styled.div`
  padding: 32px 0;

  @media screen and (min-width: 992px) {
    display: flex;
    align-items: flex-start;
    padding: 90px 0;
  }
`

export const Sidebar = styled.aside`
  margin-bottom: 32px;

  @media screen and (min-width: 992px) {
    min-width: 260px;
    width: 260px;
    margin-right: 60px;
    position: sticky;
    top: 150px;
  }
`

export const SidebarItem = styled.p`
  padding: 4px 0;
  font-size: 14px;
  border-bottom: 1px solid black;

  &:last-of-type {
    border-bottom: none;
  }
`

export const SidebarShare = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;

  @media screen and (min-width: 992px) {
    margin-top: 32px;
  }

  p {
    width: 100%;
    margin-bottom: 8px;
  }

  a {
    display: block;
    margin-right: 8px;
    width: 40px;
    height: 40px;
    border: 1px solid black;
    border-radius: 50%;
    padding: 8px;
    opacity: 0.5;

    &:hover {
      opacity: 0.75;
    }
  }
`

export const Body = styled.div`
  flex: 1;
  max-width: 100%;

  a {
    color: ${props => props.theme.colors.perracotta};
    border-bottom: 2px solid ${props => props.theme.colors.perracotta};
  }

  @media screen and (min-width: 992px) {
    max-width: calc(100% - 320px);
  }

  h2 {
    font-family: ${props => props.theme.fonts.secondary};
    font-size: 20px;
    margin-bottom: 16px;
    margin-top: 32px;

    @media screen and (min-width: 992px) {
      font-size: 24px;
    }
  }
`

export const Description = styled.div`
  padding-bottom: 32px;
  margin-bottom: 32px;
  border-bottom: 1px solid black;

  p {
    font-weight: bold;
    font-family: ${props => props.theme.fonts.secondary};
  }

  @media screen and (min-width: 992px) {
    padding-bottom: 48px;
    margin-bottom: 48px;

    p {
      font-size: 20px;
    }
  }
`

export const Text = styled.div`
  h3 {
    font-weight: normal;
    margin-top: 20px;
    margin-bottom: 16px;
    font-size: 24px;
  }

  p {
    margin-bottom: 16px;
  }

  img {
    max-width: 100%;
  }

  ul {
    margin-left: 20px;
  }

  li {
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  h6 {
    margin-top: 24px;
    font-size: 16px;
    margin-bottom: 8px;
    color: #777;

    + p {
      font-size: 16px;
      color: #777;
      a {
        display: inline-block;
        margin-bottom: 4px;
      }
    }
  }

  @media screen and (min-width: 992px) {
    p {
      font-size: 20px;
    }
  }
`
