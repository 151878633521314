/** @jsx jsx */

import PropTypes from 'prop-types'
import { Heading, jsx } from 'theme-ui'
import { ContentWrapper } from './styles'

const List = ({ content }) => {
  return (
    <div>
      <Heading as="h2">{content.title}</Heading>
      <ContentWrapper
        dangerouslySetInnerHTML={{
          __html: content.body.childMarkdownRemark.html
        }}
      />
    </div>
  )
}

List.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    body: PropTypes.shape({
      childMarkdownRemark: PropTypes.shape({
        html: PropTypes.string
      })
    })
  })
}

export default List
