import React from 'react'
import PropTypes from 'prop-types'
import { Container } from 'theme-ui'
import Section from '../Section'
import {
  Body,
  Description,
  Sidebar,
  SidebarItem,
  SidebarShare,
  Wrapper
} from './styles'
import { useLocation } from '@reach/router'

const Content = ({ article }) => {
  const { cookedBy, content, description, publishDate, subject } = article
  const location = useLocation()

  return (
    <Container as="article" variant="small">
      <Wrapper>
        <Sidebar>
          {cookedBy && (
            <SidebarItem>
              <strong>Cooked By:</strong> {cookedBy}
            </SidebarItem>
          )}
          {subject && (
            <SidebarItem>
              <strong>Subject:</strong> {subject}
            </SidebarItem>
          )}
          <SidebarItem>
            <strong>Date:</strong> {publishDate}
          </SidebarItem>
          <SidebarShare>
            <p>Share the love</p>
            <a
              target="_blank"
              rel="noreferrer"
              href={`https://www.facebook.com/sharer/sharer.php?u=${location.href}`}
            >
              <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 8H6v4h3v12h5V12h3.642L18 8h-4V6.333C14 5.378 14.192 5 15.115 5H18V0h-3.808C10.596 0 9 1.583 9 4.615V8z"></path>
              </svg>
            </a>

            <a
              target="_blank"
              rel="noreferrer"
              href={`https://twitter.com/intent/tweet?text=${location.href}`}
            >
              <svg
                viewBox="0 0 30 24"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M9.28746475,24 C20.4316842,24 26.5261215,14.7679057 26.5261215,6.76134325 C26.5261215,6.4992566 26.5199692,6.23840041 26.5088952,5.97877467 C27.6913612,5.12360933 28.7187901,4.05680595 29.5308895,2.84111766 C28.4456293,3.32345552 27.2766983,3.64829531 26.0511664,3.79594976 C27.3025378,3.04537298 28.2622917,1.85798513 28.7163291,0.442963343 C27.5449372,1.13693925 26.2492694,1.64265573 24.8687003,1.914586 C23.7625224,0.737041784 22.1887721,0 20.4464496,0 C17.0996155,0 14.386465,2.71315047 14.386465,6.05875417 C14.386465,6.53370931 14.4393745,6.99635991 14.5427326,7.4405537 C9.50894642,7.18708024 5.04362984,4.77539093 2.05608818,1.10986926 C1.5343758,2.00563958 1.23537554,3.04537298 1.23537554,4.15524225 C1.23537554,6.25685722 2.30463984,8.11238144 3.93006921,9.19764163 C2.93709305,9.16688029 2.00317867,8.89495001 1.18738785,8.43968213 C1.1861574,8.46552166 1.1861574,8.49136119 1.1861574,8.51720072 C1.1861574,11.4518329 3.27423738,13.9016662 6.04644963,14.4578313 C5.53704178,14.5956421 5.00179441,14.6706998 4.44932069,14.6706998 C4.0580364,14.6706998 3.67905665,14.6313253 3.30992053,14.5611894 C4.08141502,16.9679569 6.3183799,18.720123 8.97000769,18.7693412 C6.89669316,20.3947706 4.28443989,21.3619072 1.44455268,21.3619072 C0.956062548,21.3619072 0.473724686,21.3348372 -1.58095759e-13,21.2794668 C2.68115868,22.9971802 5.86557293,24 9.28746475,24"></path>
              </svg>
            </a>
          </SidebarShare>
        </Sidebar>
        <Body>
          {description && (
            <Description
              dangerouslySetInnerHTML={{
                __html: description.childMarkdownRemark.html
              }}
            />
          )}
          {content && (
            <>
              {content.map(section => (
                <Section key={section.id} section={section} />
              ))}
            </>
          )}
        </Body>
      </Wrapper>
    </Container>
  )
}

Content.propTypes = {
  article: PropTypes.shape({
    id: PropTypes.string,
    component: PropTypes.string,
    title: PropTypes.string,
    publishDate: PropTypes.string,
    slug: PropTypes.string.isRequired,
    subject: PropTypes.string,
    category: PropTypes.shape({
      name: PropTypes.string
    }),
    body: PropTypes.shape({
      childMarkdownRemark: PropTypes.shape({
        html: PropTypes.string
      })
    }),
    description: PropTypes.shape({
      childMarkdownRemark: PropTypes.shape({
        html: PropTypes.string
      })
    }),
    content: PropTypes.arrayOf(PropTypes.shape({})),
    afterContent: PropTypes.arrayOf(PropTypes.shape({})),
    relatedContent: PropTypes.arrayOf(PropTypes.shape({})),
    image: PropTypes.shape({
      gatsbyImageData: PropTypes.shape({}),
      title: PropTypes.string
    }),
    cookedBy: PropTypes.string
  })
}

export default Content
