import React from 'react'
import PropTypes from 'prop-types'
import { Container } from 'theme-ui'
import ArticleCard from '~/components/Blog/ArticleCard'
import { Grid, Heading, Wrapper } from './styles'

const ArticlePage = ({ content }) => (
  <Wrapper>
    <Container variant="small">
      <Heading as="h2" variant="secondaryMedium">
        Featured Stories
      </Heading>
      <Grid>
        {content.map(relatedArticle => (
          <ArticleCard key={relatedArticle.id} article={relatedArticle} />
        ))}
      </Grid>
    </Container>
  </Wrapper>
)

ArticlePage.propTypes = {
  content: PropTypes.arrayOf(PropTypes.shape({}))
}

export default ArticlePage
