import styled from '@emotion/styled'

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: left;
  flex-direction: ${p => (p.inCart ? 'row' : 'column')};
  border: ${p => (p.inCart ? '1px solid #e3e3e3' : '1px solid transparent')};
  border-radius: ${p => (p.inCart ? '24px' : '45px')};
  padding: 15px;

  &:hover {
    border-color: #e3e3e3;
  }

  &:hover .color-selector {
    display: block !important;
  }

  @media screen and (min-width: 992px) {
    padding: 30px;
  }
`

export const ImageWrapper = styled.div`
  min-width: 100px;
  margin:auto;
`

export const ContentWrapper = styled.div`
  display: flex;
  align-items: left;
  justify-content: flex-start;
  flex-direction: column;
`

export const ColorSelector = styled.div`
  display: none;
  margin-bottom: 10px;
`
