import styled from '@emotion/styled'
import { Heading } from 'theme-ui'

export const Wrapper = styled.section`
  padding: 48px 0 0;
  position: relative;

  > div {
    position: relative;
  }

  &:before {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 75%;
    background-color: ${props => props.theme.colors.cream};
  }

  @media screen and (min-width: 992px) {
    padding: 64px 0 0;
    &:before {
      height: 60%;
    }
  }
`

export const Grid = styled.div`
  display: grid;
  grid-auto-flow: row;
  gap: 16px;
  text-align: center;
`

export const Category = styled.p`
  font-size: 15px;
`

export const Title = styled(Heading)`
  max-width: 600px;
  margin: 0 auto;
`

export const Image = styled.div`
  margin-top: 32px;

  @media screen and (min-width: 992px) {
    margin-top: 50px;
  }
`
