import { graphql, useStaticQuery } from 'gatsby'

const useFeaturedArticles = () => {
  const data = useStaticQuery(graphql`
    {
      blogHero: contentfulPageSection(type: { eq: "blog/Hero" }) {
        content {
          ...ArticleCardFragment
        }
      }
    }
  `)

  return data.blogHero.content
}

export default useFeaturedArticles
