import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

const RichResults = ({ richResults }) => (
  <Helmet
    script={[{
      type: 'application/ld+json',
      innerHTML: JSON.stringify(richResults),
    }]}
  />
)

RichResults.propTypes = {
  richResults: PropTypes.object.isRequired,
}

export default RichResults
