import React from 'react'
import PropTypes from 'prop-types'
import ImageSlider from '~/components/Article/ImageSlider'
import List from '~/components/Article/List'
import ShopStory from '~/components/Article/ShopStory'
import { Text } from './Content/styles'

const Section = ({ section }) => {
  const components = {
    ImageSlider: <ImageSlider content={section} />,
    List: <List content={section} />,
    ShopStory: <ShopStory content={section} />
  }

  return section.component ? (
    components[section.component]
  ) : (
    <Text
      dangerouslySetInnerHTML={{
        __html: section.body && section.body.childMarkdownRemark.html
      }}
    />
  )
}

Section.propTypes = {
  section: PropTypes.shape({
    component: PropTypes.string,
    body: PropTypes.shape({
      childMarkdownRemark: PropTypes.shape({
        html: PropTypes.string
      })
    })
  })
}

export default Section
