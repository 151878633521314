import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Metadata, Page } from '~/components/Layout'
import { Header, Content, Section, RelatedContent } from '~/components/Article'
import useFeaturedArticles from '~/hooks/graphql/queries/use-featured-articles'
import RichResults from '~/components/RichResults'
import useArticleRichResults from '~/hooks/components/use-article-rich-results'

const ArticleTemplate = ({ data: { article } }) => {
  const SEOArticleBody = () => {
    const content = article.content?.map(({ body }) => {
      const contentBlocks = body?.childMarkdownRemark.htmlAst.children
      const validHTMLTags = ['p', 'h2']
      const validContentBlocks = contentBlocks?.filter((item) => validHTMLTags.includes(item?.tagName))
      const articleBodyOutput = validContentBlocks?.map((blocks) => blocks.children[0]?.value)?.join(' ')
      return articleBodyOutput
    })
    return content?.join(' ');
  }

  const featuredArticles = useFeaturedArticles()
  const richResults = useArticleRichResults({
    articleBody: SEOArticleBody(),
    author: article.cookedBy,
    dateCreated: article.updatedAt,
    datePublished: article.publishDate,
    description: article.description.description,
    image: article.image.file.url,
    canonical: article.canonical,
    title: article.title,
  })

  const title = article?.metadata?.title && article?.metadata?.title;
  const description = article?.metadata?.description?.description && article?.metadata?.description?.description;
  const image = article?.metadata?.image?.file?.url && article?.metadata?.image?.file?.url;
  const canonical = article?.metadata?.canonical && article?.metadata?.canonical;
  const blockSearchIndexing = article?.metadata?.blockSearchIndexing && article?.metadata?.blockSearchIndexing;

  return (
    <Page>
      <Metadata
        title={title ? title : article.title}
        description={description ? description : article.description.description}
        image={image ? image : article.image.file.url}
        canonical={canonical ? canonical : article.canonical}
        blockSearchIndexing={blockSearchIndexing && blockSearchIndexing}
      />
      <RichResults richResults={richResults} />
      <Header
        category={article.category}
        image={article.image}
        title={article.title}
      />
      <Content article={article} />
      {article.afterContent &&
        article.afterContent.map(section => (
          <Section key={section.id} section={section} />
        ))}
      {featuredArticles && <RelatedContent content={featuredArticles} />}
    </Page>
  )
}

export default ArticleTemplate

export const query = graphql`
  query ArticleQuery($slug: String, $locale: String) {
    article: contentfulBlogArticle(
      slug: { eq: $slug }
      node_locale: { eq: $locale }
    ) {
      ...ArticlePageFragment
    }
  }
`

ArticleTemplate.propTypes = {
  data: PropTypes.shape({
    article: PropTypes.shape({
      category: PropTypes.shape({
        name: PropTypes.string
      }),
      content: PropTypes.arrayOf(PropTypes.shape({
        body: PropTypes.shape({}),
      })),
      cookedBy: PropTypes.string,
      canonical: PropTypes.shape({
        __typename: PropTypes.string,
        slug: PropTypes.string
      }),
      description: PropTypes.shape({
        description: PropTypes.string,
        html: PropTypes.string,
      }),
      title: PropTypes.string,
      image: PropTypes.shape({
        gatsbyImageData: PropTypes.shape({}),
        title: PropTypes.string,
        file: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
      afterContent: PropTypes.arrayOf(PropTypes.shape({})),
      relatedContent: PropTypes.arrayOf(PropTypes.shape({})),
      publishDate: PropTypes.string,
      updatedAt: PropTypes.string,
    })
  })
}
