import styled from '@emotion/styled'
import { Heading as ThemeHeading } from 'theme-ui'

export const Wrapper = styled.section`
  padding: 32px 0;

  @media screen and (min-width: 992px) {
    padding: 64px 0;
  }
`

export const Heading = styled(ThemeHeading)`
  text-align: center;
  margin-bottom: 32px;
`

export const Grid = styled.div`
  display: grid;
  gap: 32px;

  @media screen and (min-width: 992px) {
    grid-template-columns: repeat(3, 1fr);
  }
`
